.main {
  transition: 1s;
  height: max-content;
  min-height: fit-content;
  .container {
    height: 100%;
    transition: 1s;
  }
  .logoContainer {
    position: absolute;
    transition: 1s;
    left: 50%;
    top: 1rem;
    transform: translate(-50%, 0);
    z-index: 200;
  }
  &.loading {
    overflow: hidden;
    max-height: 100vh;
    .logoContainer {
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .container {
      transform: translateY(100%);
    }
  }
}
