@font-face {
  font-family: "Selecta";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("./fonts/Selecta Regular.woff2") format("woff2");
}

@mixin T-00 {
  font-size: var(--font-size-s);
  line-height: 14px;
  letter-spacing: 0.01em;
}

@mixin T-01 {
  font-size: var(--font-size-m);
  line-height: 16px;
}

@mixin T-02 {
  font-size: var(--font-size-l);
  line-height: 100%;
}

@mixin T-03 {
  font-size: var(--font-size-xl);
  line-height: 100%;
}

@mixin R-01 {
  font-size: var(--font-size-m);
  line-height: 18px;
  text-transform: uppercase;
}
