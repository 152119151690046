*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#__next {
  height: 100%;
  min-height: 100%;
  position: relative;
}

html,
html:focus-within {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

img,
picture,
video,
canvas {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select,
input::placeholder {
  font: inherit;
}

button,
input[type="button"],
input[type="submit"],
input[type="checkbox"],
input[type="radio"],
input[type="file"] {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
  html,
  html:focus-within {
    scroll-behavior: auto;
  }
}
