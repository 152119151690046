.headerSignIn {
  // display: flex;
  justify-content: space-between;
  align-items: center;
  // @media screen and (max-width: 768px) {
  //   min-width: 300px;
  // }
  gap: 1rem;
  color: var(--color-grey);

  // the sign in button
  > div {
    cursor: pointer;
  }
}

.signinButton {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  color: var(--color-grey);
  cursor: pointer;
}
