@import "../../../styles/media.scss";

.header {
  width: 100%;
  height: calc(var(--header-height) - var(--logo-height) + 2rem);
  position: fixed;
  top: 0;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  transition: 0.75s, backdrop-filter 0s, align-items 0s;
  background-image: linear-gradient(
    180deg,
    #000000 19.44%,
    rgba(0, 0, 0, 0) 41.45%
  );
  .socialsAndLanguageToggle {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: fit-content;
    @include mobile {
      flex-direction: row-reverse;
    }
  }
  .signInContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: fit-content;
  }
  .logoContainer {
    position: absolute;
    left: 50%;
    top: 1rem;
    transform: translateX(-50%);
    transition: 0.75s;
    cursor: pointer;
    &.loading {
      opacity: 0;
    }
  }
  &.open {
    height: 100vh;
    background-image: linear-gradient(
      180deg,
      #000000 39.16%,
      rgba(0, 0, 0, 0) 139.72%
    );
    backdrop-filter: blur(30px);
    .logoContainer {
      top: calc(100%);
      transform: translate(-50%, calc(-100% - 1rem));
    }
  }
}

.socialsAndSignInContainer {
  display: flex;
  height: fit-content;
  align-items: center;
  gap: 0.5rem;
}
