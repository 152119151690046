.dialogue {
  background: #000;
  width: 75vw;
  max-width: 500px;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem;

  h4 {
    font-weight: 400;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    span {
      color: var(--color-grey);
      cursor: pointer;
    }
  }
}

.resetCodeText {
  text-align: center;
}

.paymentContainer {
  padding: 1rem;
}