.mainButton {
  text-transform: capitalize;
  text-align: center;
  border-radius: 5px;
  padding: 0 0.625rem;
  gap: 0.5rem;
  border: 0.3px solid var(--color-grey);
  transition: 0.5s;
  white-space: nowrap;
  min-height: 30px;
  line-height: 30px;
  cursor: pointer;

  &.black {
    background-color: #000;
    color: #fff;
  }
  &.white {
    background-color: #fff;
    color: var(--color-grey);
  }
  &.grey {
    background-color: var(--color-grey-light);
    color: var(--color-grey);
  }
  &.transparent-grey {
    background: #6b6b6b33;
    color: var(--color-grey);
  }
  &.yellow {
    border: 0.3px solid var(--color-yellow);
    background-color: var(--color-grey-transparent);
    color: var(--color-yellow);
  }
  &.green {
    border: 0.3px solid var(--color-green);
    background-color: var(--color-grey-transparent);
    color: var(--color-green);
  }
  &.uiOnly {
    pointer-events: none;
    user-select: none;
  }
  &:disabled {
    background: var(--color-grey);
    opacity: 0.5;
    cursor: not-allowed;
  }
}
