.headerLanguageToggle {
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
  color: var(--color-grey);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  div {
    height: fit-content;
    transition: 0.5s;
    &.selected {
      color: #fff;
    }
  }
  .toggle {
    width: 1.25rem;
    cursor: pointer;
    border: 0.5px solid var(--color-grey);
    padding: 0.1rem;
    border-radius: 100vh;
    display: flex;
    align-items: center;
    transition: 0.5s;
    > div {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: var(--color-grey);
      transition: 0.5s;
    }
    &.toggled {
      background-color: var(--color-white);
      justify-content: flex-end;
    }
  }
}
