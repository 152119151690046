@use "reset";
@use "fonts";
@import "media";
@import "typography";

:root {
  --header-height: 100px;
  --logo-height: 90px;
  --modal-z-index: 200;
  --color-white: #fff;
  --color-grey-transparent: #6b6b6b33;
  --color-grey-light: #a2a1a1;
  --color-grey: #616161;
  --color-blue: #132b58;
  --color-green: #40b036;
  --color-yellow: #ebff00;
  --font-size-xl: 30px;
  --font-size-l: 20px;
  --font-size-m: 15px;
  --font-size-s: 13px;
  --font-size-xs: 11px;

  @include mobile {
    --header-height: 120px;
  }
}

body {
  background-color: #000;
  color: #fff;
  font-family: "Selecta", sans-serif;
  overflow-x: hidden;
  font-size: var(--font-size-m);
}

// main {
//   transform: translateY(100vh);
// }

h1 {
  @include T-03;
}

h5 {
  @include T-00;
  font-weight: 400;
  color: var(--color-grey);
}

h6 {
  font-size: var(--font-size-xs);
}

.small-title {
  @include T-00;
  font-weight: 400;
  color: var(--color-grey);
}

.description {
  @include T-03;
  text-indent: 5rem;
}

.accountWelcome {
  @include T-03;
  @include desktop;
  flex: 1;
}

.textCenter {
  text-align: center;
}

.ph-025 {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.p-1 {
  padding: 1rem;
}

.m-025 {
  margin: 0.25rem;
}

.bbg {
  border-bottom: 1px solid var(--color-grey);
}

.btg {
  border-top: 1px solid var(--color-grey);
}

.hidden {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

.center {
  justify-content: center;
  display: flex;
}

.mt-h {
  margin-top: var(--header-height);
}

.pt-h {
  padding-top: var(--header-height);
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.main-input {
  padding: 0 0.5rem;
  margin: 0.125rem;
  border: 1px solid var(--color-grey);
  border-radius: 0.25rem;
  background: transparent;
  color: var(--color-white);
  outline: none;
  transition: 0.5s;
  min-height: 30px;
  line-height: 30px;
  &::placeholder {
    text-transform: capitalize;
  }
  &:focus {
    border-color: var(--color-white);
  }
}

.no-display {
  display: none;
}

button {
  svg {
    vertical-align: middle;
  }
}
