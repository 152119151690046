.CardNumberElement {
  padding: 0 0.5rem;
  margin: 0.125rem;
  border: 1px solid var(--color-grey);
  border-radius: 0.25rem;
  background: transparent;
  outline: none;
  transition: 0.5s;
  min-height: 30px;
  line-height: 30px;
  min-width: 75px;

  &::placeholder {
    text-transform: capitalize;
  }

  &:focus {
    border-color: var(--color-white);
  }

  &:nth-child(1) {
    width: 175px;
  }
  &:nth-child(2) {
    width: 125px;
  }
}

.changeSubscriptionContainer {
  display: flex;
  gap: 1rem;
  > span {
    border: 0.3px solid var(--color-grey);
    padding: 0.5rem;
    border-radius: 0.25rem;
    width: 90px;
    text-align: center;
    cursor: pointer;
    &.selected {
      background: var(--color-white);
      color: var(--color-grey);
    }
  }
}

.centerContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error {
  margin-top: 1rem;
}
