.modalOverlay {
  width: 100vw;
  height: 100vh;
  background: #00000055;
  z-index: var(--modal-z-index);
  position: fixed;
  top: 0;
  left: 0;

  &.blur {
    backdrop-filter: blur(1px);
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--modal-z-index);
  &.slide-up {
    animation: slideUp 0.5s ease;
  }
  &.slide-down {
    animation: slideDown 0.5s ease;
  }
  &.slide-left {
    animation: slideLeft 0.5s ease;
  }
  &.slide-right {
    animation: slideRight 0.5s ease;
  }
  &.fade-in {
    animation: fadeIn 0.5s ease;
  }
  &.expand {
    animation: expand 0.5s ease;
  }
}

@keyframes slideUp {
  0% {
    transform: translate(-50%, 100vh);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes slideDown {
  0% {
    transform: translate(-50%, -100vh);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes slideLeft {
  0% {
    transform: translate(-100vw, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes slideRight {
  0% {
    transform: translate(100vw, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    transform: 1;
  }
}

@keyframes expand {
  0% {
    transform: scale(0) translate(-50%, -50%);
    transform-origin: top left;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
    transform-origin: top left;
  }
}
