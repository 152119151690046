@import "../../../styles/media.scss";

.container {
  padding: 2rem;
  background-color: #000;
  border-radius: 0.25rem;
  @include mobile {
    width: 90vw;
  }
}

.welcomeText {
  text-align: center;
  margin-bottom: 2rem;
}

.formFieldset {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  legend {
    text-align: center;
  }
}

.error {
  margin-top: 1rem;
  text-align: center;
}
