.label {
  display: none;
}

.input {
  padding: 0 0.5rem;
  margin: 0.125rem;
  border: 1px solid var(--color-grey);
  border-radius: 0.25rem;
  background: transparent;
  color: var(--color-white);
  outline: none;
  transition: 0.5s;
  min-height: 30px;
  line-height: 30px;
  &::placeholder {
    text-transform: capitalize;
  }
  &:focus {
    border-color: var(--color-white);
  }
}
