@import '../../../../styles/media.scss';

.navbar {
  color: var(--color-grey);
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    text-transform: capitalize;
  }
  .activeUrl {
    color: var(--color-white);
  }
  @include mobile() {
    position: absolute;
    height: fit-content;
    top: 0;
    left: 50%;
    transform: translate(-50%, -200%);
    transition: 0.75s;
    ul {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &.open {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
